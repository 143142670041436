import { Link } from "gatsby"
import React, { Component } from "react"
import amzlogo from "../../images/amz-logo.svg"
import Navbar from "reactstrap/es/Navbar"
import NavbarBrand from "reactstrap/es/NavbarBrand"
import NavItem from "reactstrap/es/NavItem"
import DropdownToggle from "reactstrap/es/DropdownToggle"
import DropdownMenu from "reactstrap/es/DropdownMenu"
import DropdownItem from "reactstrap/es/DropdownItem"
import Dropdown from "reactstrap/es/Dropdown"
import Nav from "reactstrap/es/Nav"

export default class Header extends Component {
    constructor(props) {
        super(props)

        this.toggle = this.toggle.bind(this)
        this.toggleSearch = this.toggleSearch.bind(this)
        this.toggleDropdown = this.toggleDropdown.bind(this)
        this.state = {
            isSearchOpen: false,
            isOpen: false,
            dropIsOpen: false,
        }
    }

    toggleSearch() {
        this.setState({isSearchOpen: !this.state.isSearchOpen,})
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    toggleDropdown() {
        this.setState({
            dropIsOpen: !this.state.dropIsOpen,
        })
    }
    render() {
        return (
            <>
                <div className="header d-none d-sm-none d-md-none d-lg-block d-xl-block">
                    <div className="container">
                        <Navbar className="menu">
                            <Nav>
                                <Link to="/" className="logo-link">
                                    <NavbarBrand>
                                        <h1 className="logo-amz">Amazing<span className="logo-contrast">Tec</span></h1>
                                    </NavbarBrand>
                                </Link>
                                <div className="navItems showItems">
                                    <NavItem className="menu-item menu-item-first">
                                    <Link to="/noticias/">Notícias</Link>
                                </NavItem>
                                    <NavItem className="menu-item">
                                        <Link to="/reviews/">Reviews</Link>
                                    </NavItem>
                                    <NavItem className="menu-item">
                                        <Link to="/android/">Android</Link>
                                    </NavItem>
                                    <NavItem className="menu-item">
                                        <Link to="/apple/">Apple</Link>
                                    </NavItem>
                                    <NavItem className="menu-item">
                                        <Link to="/jogos/">Jogos</Link>
                                    </NavItem>
                                    <NavItem className="menu-item">
                                        <Link to="/ti/">TI</Link>
                                    </NavItem>
                                    <NavItem NavItem className="menu-item">
                                        <Link to="/windows/">Windows</Link>
                                    </NavItem>
                                    <Dropdown
                                      isOpen={this.state.dropIsOpen}
                                      toggle={this.toggleDropdown}

                                    >
                                        <DropdownToggle
                                          nav
                                          caret
                                          className="menu-item menu-item-dropdown"
                                        >
                                            +Amazing
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem className="menu-item ">
                                                <Link to="/quem-somos/">Quem Somos</Link>
                                            </DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem className="menu-item ">
                                                <Link to="/media-kit/">Media Kit</Link>
                                            </DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem className="menu-item ">
                                                <Link to="/contato/">Contato</Link>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </Nav>
                        </Navbar>
                    </div>
                </div>
            </>
        )
    }
}
